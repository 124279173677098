import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';

const portfolioItems = [
    {
        imgSrc: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*1qd9em4fTrIO5emWP-nHzg.png",
        categories: ["Backend", "System Design"],
        title: "The Journey of a Request to the Backend",
        link: "https://medium.com/@hnasr/the-journey-of-a-request-to-the-backend-c3de704de223",
        aos: 'fade-up',
        colClass: 'col-md-12',
    },
    {
        imgSrc: "../assets/articles/API_Versionning.png",
        categories: [],
        title: "API Versioning: Advantages, Disadvantages, and When to Use Each Type",
        link: "https://medium.com/p/a317780321d2",
        aos: 'fade-right',
        colClass: 'col-md-6',
    },
    {
        imgSrc: "https://pbs.twimg.com/profile_images/1235868806079057921/fTL08u_H_400x400.png",
        categories: ["Figma", "Webflow"],
        title: "The Spring IOC container",
        link: "https://medium.com/@abdel.kader.lafdal/the-spring-boot-ioc-container-e34d4d93b030",
        aos: 'fade-left',
        colClass: 'col-md-6',
    },
    {
        imgSrc: "https://www.opc-router.de/wp-content/uploads/2020/04/icon_rest_webservice_600x400px-300x200.png",
        categories: [],
        title: "Architectural Styles and the Design of Network-based Software Architectures",
        link: "https://ics.uci.edu/~fielding/pubs/dissertation/rest_arch_style.htm#sec_5_1_5",
        aos: 'fade-up',
        colClass: 'col-md-12',
    },

];

export default function Portfolio() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        Lightbox.option({
            resizeDuration: 200,
            fadeDuration: 600,
            imageFadeDuration: 600,
            wrapAround: true
        });
    }, []);

    return (
        <section className="portfolio-area page-section scroll-to-page" id="portfolio">
            <div className="custom-container">
                <div className="portfolio-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-grip-vertical"></i> portfolio
                        </h4>
                        <h1 className="scroll-animation">Featured <span>Articles</span></h1>
                    </div>

                    <div className="row portfolio-items">
                        {portfolioItems.map((item, index) => (
                            <div key={index} className={`${item.colClass} scroll-animation`} data-aos={item.aos}>
                                <div className="portfolio-item portfolio-full">
                                    <div className="portfolio-item-inner">
                                        <a href={item.imgSrc} data-lightbox="example-1">
                                            <img src={item.imgSrc} alt="Portfolio" />
                                        </a>
                                        <ul className="portfolio-categories">
                                            {item.categories.map((category, idx) => (
                                                <li key={idx}>
                                                    <a href={`https://www.${category.toLowerCase()}.com`}>{category}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <h2><a href={item.link}>{item.title}</a></h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
