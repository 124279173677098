import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const titles = ["Front End Developer", "Software Engineer", "Java Developer", "React Developer", "Full Stack Engineer"];

const typing = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const blink = keyframes`
  50% { border-color: transparent; }
`;

const AnimatedText = styled.span`
  display: inline-block;
  overflow: hidden;
  border-right: 0.1em solid var(--primary_color);
  white-space: nowrap;
  letter-spacing: 0.15em;
  animation: 
    ${typing} 4s steps(${props => props.steps}, end) infinite,
    ${blink} 0.75s step-end infinite;
  font-size: 2.5em;
  font-weight: bold;
  color: var(--primary_color);
`;

const HeaderWrapper = styled.div`
  text-align: center;
  margin: 50px 0;
`;

export const SectionHeader = () => {
    const [currentTitle, setCurrentTitle] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTitle((prevTitle) => (prevTitle + 1) % titles.length);
        }, 4000); // 4 seconds for each title before switching
        return () => clearInterval(interval);
    }, []);

    return (
        <HeaderWrapper className="section-header">
            <div className="titles scroll-animation" data-aos='fade-up'>
                <AnimatedText key={currentTitle} steps={titles[currentTitle].length * 2}>
                    {titles[currentTitle]}
                </AnimatedText>
            </div>
        </HeaderWrapper>
    );
}
