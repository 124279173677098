import React from 'react';

export default function Lsb() {
    return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
                {/* <img src="./assets/images/logo.png" alt="Logo" /> */}
                {/* <span className="designation">
                    Framer Designer & Developer
                    </span> */}
                {/* <SectionHeader /> */}

            </div>
            <img className="me" src="./assets/images/me.jpg" alt="Me" />
            <h2 className="email">abdel.kader.lafdal@outlook.com</h2>
            <h2 className="address">Based in Mountain View , CA</h2>
            <p className="copyright">&copy; {(new Date()).getFullYear()} Abdel. All Rights Reserved</p>
            <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
                <li>
                    <a href="https://medium.com/@abdel.kader.lafdal"><i className="lab la-medium"></i></a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/lafdal-abdel-kader"><i className="lab la-linkedin-in"></i></a>
                </li>
                <li>
                    <a href="https://github.com/kdraa12"><i className="lab la-github"></i></a>
                </li>
            </ul>
            <a href="mailto:abdel.kader.lafdal@outlook.com" className="theme-btn">
                <i className="las la-envelope"></i> Hire Me!
            </a>
        </div>
    )
}   
