import React, { useRef, useEffect } from "react";
import OwlCaraousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Testimonials() {

    const carouselRef = useRef(null);

    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])

    const handlePrevClick = () => {
        carouselRef.current.prev();
    }

    const handleNextClick = () => {
        carouselRef.current.next();
    }

    return (
        <section className="testimonial-area page-section scroll-to-page" id="testimonial">
            {/* <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script> */}

            {/* <script src="https://code.jquery.com/jquery-3.6.4.slim.min.js" integrity="sha256-a2yjHM4jnF9f54xUQakjZGaqYs/V1CYvWpoqZzC2/Bw=" crossorigin="anonymous"></script> */}


            <div className="custom-container">
                <div className="testimonial-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="lar la-comment"></i> testimonial
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Trusted by
                            <span> dozens of Clients and employers</span>
                        </h1>
                    </div>
                    <div className="testimonial-slider-wrap scroll-animation" data-aos='fade-up'>

                        <OwlCaraousel className="owl-carousel testimonial-slider owl-theme" smartSpeed="450" items="1" dots={false} ref={carouselRef}>
                            <div className="testimonial-item">
                                <div className="testimonial-item-inner">
                                    <div className="author d-flex align-items-center">
                                        <img src="../assets/images/testimonial-1.jpg" alt="portfolio" />
                                        <div className="right">
                                            <h3>Brahim Ismail</h3>
                                            <p className="designation">CEO of <a><span>YATA MEDICAL</span> </a> </p>
                                        </div>
                                    </div>
                                    <p>“Abdel Kader Lafdal's technical expertise and dedication were invaluable to YATA Medical
                                        His skills in Java, Spring, React, and AWS significantly boosted our healthcare technology projects.
                                        Abdel is a problem-solver and a team player, making him an excellent asset for the company.”</p>

                                    {/* <a href="" className="project-btn">Project</a> */}
                                </div>
                            </div>

                            <div className="testimonial-item">
                                <div className="testimonial-item-inner">
                                    <div className="author d-flex align-items-center">
                                        <img src="../assets/images/testimonial-2.jpg" alt="portfolio" />
                                        <div className="right">
                                            <h3>Abderahman Sidi Mohamed</h3>
                                            <p className="designation">Product Management of <span>Invision App Inc</span></p>
                                        </div>
                                    </div>
                                    <p>“Abdel was an integral part of our team at Software Savants.
                                        His expertise in full-stack development, particularly with Java and Spring Boot,
                                        played a key role in multiple regional projects.
                                        Abdel’s innovative approach and commitment to excellence consistently
                                        delivered outstanding results. He is a reliable and dedicated professional
                                        who excels in both independent and team settings. I highly recommend
                                        Abdel for any senior engineering role.”</p>

                                </div>
                            </div>


                        </OwlCaraousel>
                        <div className="testimonial-footer-nav">
                            <div className="testimonial-nav d-flex align-items-center">
                                <button className="prev" onClick={handlePrevClick} ><i className="las la-angle-left"></i></button>
                                <div id="testimonial-slide-count"><span className="left">1</span> /2</div>
                                <button className="next" onClick={handleNextClick} ><i className="las la-angle-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}