import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Skills() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="skills-area page-section scroll-to-page" id="skills">
            <div className="custom-container">
                <div className="skills-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-right'>
                            <i className="las la-shapes"></i> my skills
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'><span>Tech Stack</span></h1>
                    </div>

                    <div className="row skills text-center">
                        <div className="col-md-3 scroll-animation" data-aos='fade-up'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/logos/java.svg" height={"50px"} width={"50px"} alt="java" />
                                    <h1 className="percent">JAVA</h1>
                                </div>
                                {/* <p className="name">Figma</p> */}
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-down'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/logos/aws.svg" height={"50px"} width={"50px"} alt="AWS" />
                                    <h1 className="percent">AWS</h1>
                                </div>
                                {/* <p className="name">Framer</p> */}
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/logos/spring.svg" height={"50px"} width={"50px"} alt="Spring" />
                                    <h1 className="percent">Spring</h1>
                                </div>
                                {/* <p className="name">Webflow</p> */}
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/react.png" height={"50px"} width={"50px"} alt="React" />
                                    <h1 className="percent">React</h1>
                                </div>
                                {/* <p className="name">React</p> */}
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/logos/nodejs.svg" height={"50px"} width={"50px"} alt="Node.JS" />
                                    <h1 className="percent">Node</h1>
                                </div>
                                {/* <p className="name">WordPress</p> */}
                            </div>
                        </div>

                        <div className="col-md-3 scroll-animation" data-aos='fade-up'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/logos/docker.svg" height={"50px"} width={"50px"} alt="Docker" />
                                    <h1 className="percent">Docker</h1>
                                </div>
                                {/* <p className="name">WordPress</p> */}
                            </div>
                        </div>

                        <div className="col-md-3 scroll-animation" data-aos='fade-down'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/logos/js.svg" height={"50px"} width={"50px"} alt="JS" />
                                    <h1 className="percent">JS</h1>
                                </div>
                                {/* <p className="name">WordPress</p> */}
                            </div>
                        </div>


                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/logos/kafka.svg" height={"50px"} width={"50px"} alt="Kafka" />
                                    <h1 className="percent">Kafka</h1>
                                </div>
                                {/* <p className="name">WordPress</p> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
