import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="services-area page-section scroll-to-page" id="services">
            <div className="custom-container">
                <div className="services-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-stream"></i> Services
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                    </div>

                    <div className="services-items">
                        <div className="service-item scroll-animation" data-aos='fade-up'>
                            <i className="las la-laptop-code"></i>
                            <h2>Full-Stack Development</h2>
                            <p>Expert in building scalable web applications using React, Java, Spring Boot, and AWS.</p>
                            <span className="projects">21 Projects</span>
                        </div>
                        <div className="service-item scroll-animation" data-aos='fade-up'>
                            <i className="las la-server"></i>
                            <h2>Backend Development</h2>
                            <p>Specialized in creating robust backend systems with Java, Spring, MySQL, and MongoDB.</p>
                            <span className="projects">12 Projects</span>
                        </div>
                        <div className="service-item scroll-animation" data-aos='fade-up'>
                            <i className="las la-code"></i>
                            <h2>Frontend Development</h2>
                            <p>Proficient in developing efficient user interfaces using advanced frontend technologies.</p>
                            <span className="projects">25 Projects</span>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
