import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Form, Button, message } from 'antd';
import axios from 'axios';

export default function Contact() {
    useEffect(() => {
        AOS.init({ duration: 1300 });
    }, []);

    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (e) => {
        e.preventDefault();
        setLoading(true);
        form.validateFields()
            .then(values => axios.post('https://905afps3tc.execute-api.us-east-1.amazonaws.com/prod/', { body: JSON.stringify(values) }))
            .then(res => message.success('Your message was sent successfully.'))
            .then(() => setFormSubmitted(true))
            .then(() => form.resetFields())
            .catch(err => message.error('Something went wrong. Please try again.'))
            .finally(() => setLoading(false));


    };


    return (
        <section className="contact-area page-section scroll-content" id="contact">

            <div className="custom-container">
                {formSubmitted ? <div className="contact-content content-width section-header" >
                    <h1 className="scroll-animation" data-aos='fade-up'>
                        Your message was sent successfully.  <span>  We will get back to you shortly.</span>
                    </h1>
                </div> : (


                    <div className="contact-content content-width">
                        <div className="section-header">
                            <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                                <i className="las la-dollar-sign"></i> contact
                            </h4>
                            <h1 className="scroll-animation" data-aos='fade-up'>Let's Work <span>Together!</span></h1>
                        </div>
                        <h3 className="scroll-animation" data-aos='fade-up'>abdel.kader.lafdal@outlook.com</h3>
                        <p id="required-msg">* Marked fields are required to fill.</p>

                        <Form
                            className="contact-form scroll-animation"
                            data-aos='fade-up'
                            layout="vertical"
                            form={form}
                        >
                            <div className="alert alert-success messenger-box-contact__msg" style={{ display: 'none' }} role="alert">
                                Your message was sent successfully.
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item
                                        name="fullName"
                                        rules={[{ required: true, message: 'Please input your full name!' }]}
                                    >
                                        <div className="input-group">
                                            <label htmlFor="full-name">Full Name <sup>*</sup></label>
                                            <input type="text" name="full-name" id="full-name" placeholder="Your Full Name" />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Please input your email!' },
                                            { type: 'email', message: 'The input is not a valid email!' },
                                        ]}
                                    >
                                        <div className="input-group">
                                            <label htmlFor="email">Email <sup>*</sup></label>
                                            <input type="email" name="email" id="email" placeholder="Your email address" />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        name="phone"
                                    >
                                        <div className="input-group">
                                            <label htmlFor="phone-number">Phone <span>(optional)</span></label>
                                            <input type="text" name="phone-number" id="phone-number" placeholder="Your phone number" />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        name="subject"
                                        rules={[{ required: true, message: 'Please input the subject!' }]}
                                    >
                                        <div className="input-group">
                                            <label htmlFor="subject">Subject <sup>*</sup></label>
                                            <input type="text" name="subject" id="subject" placeholder="Your subject" />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="col-md-12">
                                    <Form.Item
                                        name="message"
                                        rules={[{ required: true, message: 'Please input your message!' }]}
                                    >
                                        <div className="input-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea name="message" id="message"
                                                placeholder="Write your message here ..." />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div className="col-md-12">
                                    <div className="input-group submit-btn-wrap">
                                        {/* <button className="theme-btn" name="submit"
                                        type="submit"
                                        
                                        id="submit-form">send message</button> */}
                                        <Button className='theme-btn'
                                            loading={loading}
                                            onClick={onFinish}>
                                            send message
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
            </div>
        </section>
    );
}
