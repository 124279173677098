import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="about-area page-section scroll-to-page" id="about">
            <div className="custom-container">
                <div className="about-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="lar la-user"></i> About
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Do not walk away from complexity<br />
                            <span>Run</span></h1>
                    </div>
                    <p className="scroll-animation" data-aos='fade-up'>Highly motivated Senior Full-Stack Software Engineer with 6 years of experience creating enterprise-level applications using User-Centered
                        Design. Proficient in Java, Spring, React, MySQL, AWS, and MongoDB. Proven ability to deploy fault-tolerant, low-latency, scalable APIs,
                        and web apps. Open to relocation for exciting opportunities.</p>
                </div>
            </div>
        </section>
    )
}
