import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    return (
        <section className="resume-area page-section scroll-to-page" id="resume">
            <div className="custom-container">
                <div className="resume-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-briefcase"></i> Resume
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'><span>Experience</span></h1>
                    </div>

                    <div className="resume-timeline">
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2021 - Present</span>
                            <h2>Senior Full-Stack Software Engineer</h2>
                            <p>YATA Medical</p>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-left'>
                            <span className="date">2018 - 2021</span>
                            <h2>Full-Stack Software Engineer</h2>
                            <p>Software Savants</p>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2023 - 2024</span>
                            <h2>Master of Science in Computer Science</h2>
                            <p>Maharishi International University</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
